
const sliderButtons = document.querySelector('.slider-menu').children;
for (let bt of sliderButtons){
    bt.innerHTML = "■";
}

const toggles = document.querySelectorAll('a.submenu')
if (toggles) {
    toggles.forEach((toggle, index) => {
        toggle.onclick = function (ev) {
            console.log(ev)
            ev.preventDefault()
        }
    })
}